import React from "react";
import SectorPage from "./SectorPage";
import Cover from "images/CareerFields/scaled/physiotherapy-cover-2_2560.webp";
import Icon1 from "images/world.svg";
import Icon2 from "images/suitcase.svg";
import Icon3 from "images/hospital.svg";
import Icon4 from "images/house.svg";
import Image1 from "images/physiotherapy-image-1.png";
import Image2 from "images/physiotherapy-image-2.png";

const intro = (
  <>
    <strong>Are you a physiotherapy student or professional seeking a unique learning experience in Tanzania ? </strong>
    HealthMission Africa's physiotherapy internship program provides a perfect opportunity for you to gain clinical experience in a dynamic healthcare setting.
  </>
);

const bulletPoints = [
  { icon: Icon1, text: "Hands-on physiotherapy experience abroad" },
  { icon: Icon2, text: "Diverse case exposure" },
  { icon: Icon3, text: "Insights into healthcare challenges" },
  { icon: Icon4, text: "Comprehensive package with accommodation and food" }
];

const firstText = <p><strong>In our program,</strong> you will have the chance to work in the field of physiotherapy at <strong>renowned hospitals</strong> in Arusha, Tanzania. This experience will allow you to implement your <strong> knowledge</strong> in a <strong>real-world setting</strong> and refine your skills under the mentorship of experienced <strong>physiotherapy professionals.</strong><br /><br />
  In the Physiotherapy Department, you will be exposed to a broad array of cases that might be less common in your home country. Dealing with different patient demographics and health conditions will broaden your clinical expertise and enhance your capabilities to diagnose and devise treatment plans for a variety of physical issues.<br /><br />
  The Tanzanian healthcare system, faced with limited resources and funding, offers unique challenges. By immersing yourself in this environment, you will gain a comprehensive understanding of the complexities and ingenuity required to deliver physiotherapy services in underserved communities. This experience will instill adaptability and resilience in you, qualities that are invaluable in your future career as a physiotherapist.
</p>;

const secondText = <p>As part of our <strong>comprehensive package</strong>, we offer the <strong>accommodation</strong> (with security), daily <strong>breakfast</strong>, <strong>dinner</strong> on weekdays, an <strong>orientation day</strong>, <strong>airport pick-up</strong>, and <strong>hospital placement assistance</strong>.<br /><br /><br />
  But it’s not just about the clinical experience. Our volunteers also have the opportunity to immerse themselves in Tanzanian culture and experience the beauty of the country. We offer assistance in organising activities such as safaris, trips to Zanzibar, Mount Kilimanjaro climb, hot springs day trips, local markets visits, and other cultural activities.<br /><br /><br />
  By combining clinical skills development with cultural experiences, HealthMission Africa offers a comprehensive, immersive program that is unmatched in its scope and impact. Join us and experience the adventure of a lifetime while making a positive impact on the lives of others.
</p>;

const Physiotherapy = () => {
  return (
    <SectorPage
      cover={Cover}
      title={"Physiotherapy"}
      intro={intro}
      bulletPoints={bulletPoints}
      textOne={firstText}
      textTwo={secondText}
      imageOne={Image1}
      imageTwo={Image2}
    />
  );
};

export default Physiotherapy;
