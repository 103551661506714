import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, useLocation, useMatch } from "react-router-dom";
import { animated, useTransition, config } from "react-spring";
import translations from "i18n/translations.json";
import { useTheme } from "@mui/styles";

import { Box, useMediaQuery, Typography } from "@mui/material";
// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
import MainAppBar from "bundles/Layouts/MainAppBar";
import ScrollToTop from "bundles/common/utils/scrollToTop";
import LandingPage from "../pages/LandingPage";
import CareerFields from "../pages/CareerFields";
import NGO from "../pages/NGO";
import AboutUs from "../pages/AboutUs";
import OurHouse from "../pages/OurHouse";
import Gallery from "../pages/Gallery";
import Pricing from "../pages/Pricing";
import Apply from "../pages/Apply";
import MedicalAndNursing from "../pages/MedicalAndNursing";
import Dentistry from "../pages/Dentistry";
import Physiotherapy from "../pages/Physiotherapy";
import Volunteering from "../pages/Volunteering";
import MobileBlurPhoto from "images/mobile-blur-photo.png";

const MobileMessage = () => (
  <Box
    style={{
      position: "relative",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    }}
  >
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${MobileBlurPhoto})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(8px)",
        zIndex: -1,
      }}
    />
    <Typography variant="h1" style={{ "color": "white", "padding": 20 }}>
      HealthMission Africa's website is currently unavailable on mobile devices.
    </Typography>
    <Typography variant="body1" style={{ "color": "white", "padding": 20 }}>
      Please visit us on a desktop or laptop for the best experience.
    </Typography>
  </Box>
);

const RoutesComponent = () => {
  const currentLocale = useSelector((state) => state.i18nLocale);
  const location = useLocation();
  const isLandingPage = location.pathname === `/${currentLocale}`;

  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1, delay: isLandingPage ? 0 : 100 },
    leave: { opacity: 0, delay: isLandingPage ? 0 : 100 },
    config: { duration: 400, tension: 200, friction: 40 },
  });

  const i18nLocale = useSelector((state) => state.i18nLocale);

  return transitions((props, item) => (

    <Routes location={item}>
      <Route
        path={`/${i18nLocale}/career_fields`}
        element={<animated.div style={props}><CareerFields /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/career_fields/medical_and_nursing`}
        element={<animated.div style={props}><MedicalAndNursing /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/career_fields/dentistry`}
        element={<animated.div style={props}><Dentistry /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/career_fields/physiotherapy`}
        element={<animated.div style={props}><Physiotherapy /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/career_fields/volunteering`}
        element={<animated.div style={props}><Volunteering /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/ngo`}
        element={<animated.div style={props}><NGO /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/about_us`}
        element={<animated.div style={props}><AboutUs /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/our_house`}
        element={<animated.div style={props}><OurHouse /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/gallery`}
        element={<animated.div style={props}><Gallery /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/pricing`}
        element={<animated.div style={props}><Pricing /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/apply`}
        element={<animated.div style={props}><Apply /></animated.div>}
      />
      <Route
        path={`/${i18nLocale}/`}
        element={<animated.div style={props}><LandingPage /></animated.div>}
      />
      {/* <Route
                  path={`/${i18nLocale}/`}
                  element={<LandingPage />}
                /> */}
    </Routes>
  ));
};

const MainContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const messages = translations[locale];
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // if (!isDesktop) {
  //   return <MobileMessage />;
  // }

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        <Box
          id="recruiter-main-box"
          display="flex"
          flexDirection="column"
          style={{ minHeight: "100vh" }}
          bgcolor="#F8F8F8"
        >
          <MainAppBar />
          <Box
            className={theme.background}
            style={{ height: "100%", flexGrow: 10 }}
          >
            <ScrollToTop />
            <RoutesComponent />
          </Box>
        </Box>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default MainContainer;
