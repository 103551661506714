export const validateDateRange = (fromDate, toDate, enqueueSnackbar) => {
  if (!fromDate || !toDate) {
    enqueueSnackbar("Please select both start and end dates.", { variant: "error" });
    return false;
  }

  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  if (startDate < currentDate) {
    enqueueSnackbar("Start date cannot be in the past.", { variant: "error" });
    return false;
  }

  if (endDate <= startDate) {
    enqueueSnackbar("End date should be after the start date.", { variant: "error" });
    return false;
  }

  const numberOfDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

  if (numberOfDays < 14) {
    enqueueSnackbar("The minimum duration should be 14 days.", { variant: "error" });
    return false;
  }

  return true;
};
