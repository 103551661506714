import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AppBar, Toolbar, Button, Box, Typography, useMediaQuery, Drawer } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import blueLogo from "images/blue-logo.svg";
import whiteLogo from "images/white-logo.svg";
import smallBlueLogo from "images/small-blue-logo.svg";
import smallWhiteLogo from "images/small-white-logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const MainAppBar = () => {
  const location = useLocation();
  const theme = useTheme();
  const i18nLocale = useSelector(state => state.i18nLocale);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isLanding = location.pathname === `/${i18nLocale}`;

  const logoToUse = isDesktop ? (isLanding ? whiteLogo : blueLogo) : (isLanding ? smallWhiteLogo : smallBlueLogo);

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const appBarStyle = {
    background: isLanding ? "transparent" : "white",
    boxShadow: isLanding ? "none" : "none",
    transition: "background-color 0.3s",
  };

  const textStyle = {
    color: isLanding ? "white" : theme.palette.secondary.main,
    textShadow: isLanding? "1px 1px 2px rgba(0, 0, 0, 0.1)" : "none"
  };

  const sections = [
    "career_fields",
    "ngo",
    "pricing",
    "our_house",
    "about_us",
    "gallery",
  ];

  const selectedSection = useMemo(() => {
    return sections.find(sec => location.pathname.startsWith(`/${i18nLocale}/${sec}`));
  }, [location.pathname, i18nLocale]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <AppBar position={isLanding ? "absolute" : "relative"} style={appBarStyle}>
      <Toolbar style={{
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: isDesktop ? "6rem" : "1rem",
        paddingRight: isDesktop ? "6rem" : "1rem",
        paddingTop: isDesktop ? "0rem" : "0.2rem",
        paddingBottom: isDesktop ? "0rem" : "0.2rem"
      }}>
        <Link to={`/${i18nLocale}`} style={{ textDecoration: "none", color: "inherit" }}>
          <img
            src={logoToUse}
            alt="HealthMission Africa Logo"
            style={{ height: "40px", width: "auto" }}
          />
        </Link>

        {isDesktop ? (
          <>
            <Box display="flex" justifyContent="center" flexGrow={1}>
              {sections.map((section) => (
                <Box key={section} color="inherit" style={{ ...textStyle, marginRight: "2.2rem", marginLeft: "2.2rem" }}>
                  <Link to={`/${i18nLocale}/${section}`} style={{ textDecoration: "none", color: "inherit" }}>
                    <Typography variant="h5" style={{ fontWeight: section === selectedSection ? "bold" : "normal" }}>
                      <FormattedMessage id={`landing_page.${section}`} />
                    </Typography>
                  </Link>
                </Box>
              ))}
            </Box>
            <Button
              color="inherit"
              style={{ ...textStyle, textTransform: "none", marginRight: "20px" }}
              onClick={() => {
                const contactFormElement = document.getElementById("contact-form");
                if (contactFormElement) {
                  contactFormElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
            >
              <Typography variant="h5">
                <FormattedMessage id="landing_page.contact_us" />
              </Typography>
            </Button>
            <Link to={`/${i18nLocale}/apply`} style={{ textDecoration: "none", color: "inherit" }}>
              <Button
                sx={{
                  ...textStyle,
                  textTransform: "none",
                  border: `1px solid ${isLanding ? "white" : theme.palette.secondary.main }`,
                  borderRadius: "8px",
                  padding: "8px 16px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  }
                }}
              >
                <Typography variant="h5">
                  <FormattedMessage id="landing_page.apply" />
                </Typography>
              </Button>
            </Link>
          </>
        ) : (
          <Button onClick={toggleDrawer(true)} style={{ ...textStyle, fontSize: "2rem", padding: "0.5rem" }}>
            ☰
          </Button>
        )}
      </Toolbar>

      {!isDesktop && (
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundColor: isLanding ? "rgba(0, 0, 0, 0.8)" : "white",
              position: "relative",
            }
          }}
        >
          <Box sx={{
            height: "100%",
            color: "white",
            paddingTop: theme.spacing(8),
            position: "relative",
            pt: 12,
          }}>
            <Typography variant="h2" sx={{ textAlign: "center", color: isLanding ? "white" : theme.palette.secondary.main }}>
              MENU
            </Typography>
            <div style={{ marginLeft: "28px", marginTop: "28px" }}>
              <Box style={{ ...textStyle, padding: "1rem" }}>
                <Link to={`/${i18nLocale}`} style={{ textDecoration: "none", color: "inherit" }} onClick={toggleDrawer(false)}>
                  <Typography variant="h4" style={{ fontSize: "24px" }}>
                    Home
                  </Typography>
                </Link>
              </Box>
              {sections.map((section) => (
                <Box key={section} color="inherit" style={{ ...textStyle, padding: "1rem" }}>
                  <Link to={`/${i18nLocale}/${section}`} style={{ textDecoration: "none", color: "inherit" }} onClick={toggleDrawer(false)}>
                    <Typography variant="h4" style={{ fontWeight: section === selectedSection ? "bold" : "normal", fontSize: "24px" }}>
                      <FormattedMessage id={`landing_page.${section}`} />
                    </Typography>
                  </Link>
                </Box>
              ))}
              <Box color="inherit" style={{ ...textStyle, padding: "1rem" }}>
                <Link to={`/${i18nLocale}/apply`} style={{ textDecoration: "none", color: "inherit" }} onClick={toggleDrawer(false)}>
                  <Typography variant="h4" style={{ fontWeight: location.pathname.startsWith(`/${i18nLocale}/apply`) ? "bold" : "normal", fontSize: "24px" }}>
                    <FormattedMessage id="landing_page.apply" />
                  </Typography>
                </Link>
              </Box>
            </div>
          </Box>
          <Box sx={{ textAlign: "center", marginTop: theme.spacing(2), marginBottom: theme.spacing(4) }}>
            <img
              src={logoToUse}
              alt="Logo"
              style={{ height: "40px", width: "auto", marginBottom: "16px" }}
            />
            <Typography variant="h3" color={isLanding ? "white" : theme.palette.secondary.main}>
              ©️ HealthMission Africa 2024
            </Typography>
          </Box>
          <IconButton
            onClick={toggleDrawer(false)}
            sx={{
              position: "absolute",
              top: theme.spacing(1),
              right: theme.spacing(2),
              color: isLanding ? "white" : "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Drawer>
      )}
    </AppBar>
  );
};

export default MainAppBar;
