import Poppins from "fonts/Poppins-Light.ttf";
import Avenir from "fonts/Avenir-Regular.ttf";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
// import { variants as buttonVariants } from "./mui_components/button";

const poppins = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  src: `
  local('Poppins'),
  url(${Poppins}) format('truetype')
`,
};

const avenir = {
  fontFamily: "Avenir",
  fontStyle: "normal",
  src: `
  local('Avenir'),
  url(${Avenir}) format('truetype')
`,
};

const breakpoints = createBreakpoints({});

const theme = {
  typography: {
    fontFamily: "Poppins, Avenir, Roboto, Helvetica, Arial, sans-serif",
    emphasis: {
      wordBreak: "normal",
      fontFamily: "Poppins",
      fontSize: "56px",
      fontWeight: 600,
      lineHeight: "75px",
      [breakpoints.down("lg")]: {
        fontSize: "48px",
      },
    },
    h1: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "36px",
      fontWeight: 400,
      [breakpoints.down("lg")]: {
        fontSize: "18px",
      },
    },
    h2: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "28px",
      fontWeight: 400,
      letterSpacing: "-0.3333333432674408px",
      color: "#2A334E",
      [breakpoints.down("lg")]: {
        fontSize: "16px",
      },
    },
    h3: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "24px",
      fontWeight: 400,
      [breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    h4: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "22px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      lineHeight: 1.195,
      [breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    h5: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "18px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    h6: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "16px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    h7: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    h8: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    body1: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: 300,
      letterSpacing: "-0.3333333432674408px",
      [breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    body1bold: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: 700,
      letterSpacing: "-0.3333333432674408px",
      [breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    body2: {
      wordBreak: "normal",
      fontFamily: "Avenir",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
      [breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    body2bold: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0.15000000596046448px",
      [breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    body3: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.05000000596046448px",
    },
    body3bold: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0.05000000596046448px",
    },
    body4: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
    },
    subtitle0: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "48px",
      fontWeight: 400,
      lineHeight: "56px",
    },
    subtitle1: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0.15000000596046448px",
    },
    subtitle2: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontWeight: 300,
      letterSpacing: "-0.33px",
      color: "#11111180",
    },
    caption: {
      display: "block",
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "11px",
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "14.08px",
      color: "#00000080",
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      blue: "#173571",
      sh_lightgrey: "rgba(0, 0, 0, 0.06)",
      grey_background: "rgba(255, 255, 255, 0.15)",
      sh_grey: "#B2B2B2",
      main: "#000",
      blue_grey: "#D3E0EA",
    },
    background: { paper: "#fff", default: "#F9FAFF" },
    warning: { main: "#FAC403" },
    primary: {
      lighter: "rgba(0, 0, 0, 0.4)",
      light: "rgba(0, 0, 0, 1)",
      main: "#000",
      dark: "rgba(0, 0, 0, 1)",
      smoke: "rgba(0, 0, 0, 0.04)",
      contrastText: "#fff",
    },
    secondary: {
      lighter: "#3e4c7440",
      light: "#3e4c74",
      main: "#173571",
      dark: "#1b2132",
      smoke: "#eef0f6",
      contrastText: "#fff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [poppins, avenir]
      },
      fallback: [
        { "@font-face": poppins },
        { "@font-face": avenir },
      ],
    },
  },
};

export default theme;
