import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import FadeInUp from "bundles/common/utils/FadeInUp";
import Carousel from "react-material-ui-carousel";

import SeePriceButton from "buttons/see-price.svg";
import SeeTeamButton from "buttons/see-team.svg";
import ArrowTopRightBig from "images/arrow-top-right-big.svg";
import ArrowRight from "images/arrow-right.svg";

import GroupPicture2 from "images/LandingPage/scaled/group-picture-2_1280.webp";
import Volunteering from "images/LandingPage/scaled/volunteering_1280.webp";
import NGOLogo from "images/ngo-logo_1280.webp";

import LocationPictures from "images/LandingPage/scaled/location-pictures_1280.webp";
import LocationPicture1 from "images/LandingPage/scaled/location-1_1280.webp";
import LocationPicture2 from "images/LandingPage/scaled/location-2_1280.webp";
import LocationPicture3 from "images/LandingPage/scaled/location-3_1280.webp";
import LocationPicture4 from "images/LandingPage/scaled/location-4_1280.webp";

import OurPrograms from "./OurPrograms";
import ContactForm from "bundles/Main/components/ContactForm";

import CarouselLanding from "bundles/Main/partials/CarouselLanding";
import Welcome from "bundles/Main/partials/Welcome";
import LandingReviews from "bundles/Main/partials/LandingReviews";

const LandingPage = () => {
  const theme = useTheme();
  const i18nLocale = useSelector(state => state.i18nLocale);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [seePriceHovered, setSeePriceHovered] = useState(false);
  const [seeTeamHovered, setSeeTeamHovered] = useState(false);
  const [arrowHovered, setArrowHovered] = useState(false);

  return (
    <>
      <CarouselLanding />
      <Welcome />
      <OurPrograms/>

      {/* How it works  */}
      <Box display={isDesktop ? "flex" : "block"} alignItems="center" justifyContent="space-between" bgcolor="white">
        <Box style={{
          width: isDesktop ? "50%" : "100%",
          padding: isDesktop ? "0 110px" : "80px 60px",
          order: isDesktop ? 1 : 0
        }}>
          <FadeInUp>
            <Typography variant="h2" align="left" style={{ fontSize: isDesktop ? "54px" : "38px", marginBottom: "32px" }}>
              How it <br /><strong><em>works</em></strong>
            </Typography>
          </FadeInUp>
          <FadeInUp>
            <Typography variant="body1" style={{ fontSize: isDesktop ? "24px" : "18px", marginBottom: "32px" }}>
              Our interns/volunteers are placed in hospitals throughout <strong>Tanzania</strong> and have the opportunity to
              work in the departments of <strong>their choice</strong>, gaining exposure to a wide range of cases that they may
              not encounter in their home countries. They are mentored by experienced <strong>medical professionals </strong>
              and gain hands-on experience that is difficult to match elsewhere.
            </Typography>
          </FadeInUp>
          {isDesktop && (
            <FadeInUp>
              <Box mt={6}>
                <Link to={`/${i18nLocale}/pricing`}>
                  <img
                    src={SeePriceButton}
                    alt="See Price"
                    style={{
                      cursor: "pointer",
                      transform: seePriceHovered ? "scale(1.1)" : "scale(1)",
                      transition: "all 0.3s ease",
                    }}
                    onMouseEnter={() => setSeePriceHovered(true)}
                    onMouseLeave={() => setSeePriceHovered(false)}
                  />
                </Link>
              </Box>
            </FadeInUp>
          )}
        </Box>
        <FadeInUp style={{ width: isDesktop ? "50%" : "100%", order: isDesktop ? 0 : 1 }}>
          <img src={GroupPicture2} alt="Group" style={{ width: "100%" }} />
        </FadeInUp>
      </Box>

      {/* Our Vision */}
      <Box display={isDesktop ? "flex" : "block"} alignItems="center" justifyContent="space-between" bgcolor="white">
        <Box style={{
          width: isDesktop ? "50%" : "100%",
          paddingRight: isDesktop ? "80px" : "60px",
          paddingLeft: isDesktop ? "120px" : "60px",
          paddingTop: isDesktop ? "0" : "60px",
          paddingBottom: isDesktop ? "0" : "60px",
          backgroundColor: "white",
          order: isDesktop ? 0 : 1
        }}>
          <FadeInUp>
            <Typography variant="h2" align="left" style={{ fontSize: isDesktop ? "54px" : "38px", marginBottom: "32px" }}>
              Our <br /><strong><em>Vision</em></strong>
            </Typography>
          </FadeInUp>
          <FadeInUp>
            <Typography variant="body1" style={{ fontSize: isDesktop ? "24px" : "18px", marginBottom: "32px" }}>
              At HealthMission, we believe that healthcare is a fundamental <strong>human right</strong>, and we are committed to <strong>positively impacting</strong> the communities we serve.
              For this reason, we organise community outreach projects in the villages surrounding the cities where our <strong>volunteers</strong> work.
            </Typography>
          </FadeInUp>
          {isDesktop && (
            <FadeInUp>
              <Box mt={6}>
                <Link to={`/${i18nLocale}/about_us`}>
                  <img
                    src={SeeTeamButton}
                    alt="See Price"
                    style={{
                      cursor: "pointer",
                      transform: seeTeamHovered ? "scale(1.1)" : "scale(1)",
                      transition: "all 0.3s ease",
                    }}
                    onMouseEnter={() => setSeeTeamHovered(true)}
                    onMouseLeave={() => setSeeTeamHovered(false)}
                  />
                </Link>
              </Box>
            </FadeInUp>
          )}
        </Box>

        <FadeInUp style={{ width: isDesktop ? "50%" : "100%", order: isDesktop ? 1 : 0 }}>
          <img src={Volunteering} alt="Group" style={{ width: "100%" }} />
        </FadeInUp>
      </Box>

      {/* Our partners */}
      <Box display={isDesktop ? "flex" : "block"} alignItems="center" justifyContent="space-between" bgcolor="#F8F8F8" style={{ height: isDesktop ? "600px" : "auto" }}>
        <Box style={{
          width: isDesktop ? "60%" : "100%",
          paddingRight: isDesktop ? "80px" : "60px",
          paddingLeft: isDesktop ? "120px" : "60px",
          paddingTop: isDesktop ? "0" : "60px",
          order: isDesktop ? 0 : 1
        }}>
          <FadeInUp>
            <Typography variant="h2" align="left" style={{ fontSize: isDesktop ? "54px" : "38px", marginBottom: "32px" }}>
              Our <br /><strong><em>Partners</em></strong>
            </Typography>
          </FadeInUp>
          <FadeInUp>
            <Typography variant="body1" style={{ fontSize: isDesktop ? "24px" : "18px", marginBottom: "32px" }}>
              These projects are organized by our sister NGO, Health Community Outreach Program,
              an integral part of HealthMission Africa's mission to promote health equity and
              wellness in Tanzania, and provide medical care to people who may otherwise not have access to it.
            </Typography>
          </FadeInUp>
          <FadeInUp style={{ textAlign: isDesktop ? "left" : "right" }}>
            <Link to={`/${i18nLocale}/ngo`}>
              <img
                src={isDesktop ? ArrowTopRightBig : ArrowRight}
                alt="Link to NGO"
                style={{
                  cursor: "pointer",
                  transform: isDesktop
                    ? (arrowHovered ? "scale(1)" : "scale(0.8)")
                    : (arrowHovered ? "scale(1.4)" : "scale(1.2)"),
                  transition: "all 0.3s ease",
                }}
                onMouseEnter={() => setArrowHovered(true)}
                onMouseLeave={() => setArrowHovered(false)}
              />
            </Link>
          </FadeInUp>
        </Box>
        <FadeInUp style={{
          width: isDesktop ? "40%" : "100%",
          padding: isDesktop ? "0" : "40px 60px",
          order: isDesktop ? 1 : 0,
          marginRight: isDesktop ? "140px" : "0px",
          display: "flex",
          justifyContent: isDesktop ? "flex-end" : "center",
          alignItems: "center"
        }}>
          <img src={NGOLogo} alt="Health Community Outreach Program" style={{ width: isDesktop ? "72%" : "90%" }} />
        </FadeInUp>
      </Box>

      {/* The location */}
      <Box
        bgcolor="white"
        padding={isDesktop ? "120px" : "0"}
      >
        <Box padding={isDesktop ? "0" : "40px 60px"} >
          <FadeInUp>
            <Typography
              variant="h2"
              align={isDesktop ? "left" : "left"}
              style={{ fontSize: isDesktop ? "54px" : "38px", marginBottom: "32px" }}
            >
              The <br /><strong><em>Location</em></strong>
            </Typography>
          </FadeInUp>
          <FadeInUp>
            <Typography
              variant="body1"
              style={{
                fontSize: isDesktop ? "24px" : "18px",
                marginBottom: "32px",
                marginLeft: isDesktop ? "8px" : "0px",
                width: isDesktop ? "80%" : "100%"
              }}
            >
              It’s not just about the clinical experience. Our volunteers also have the
              opportunity to immerse themselves in <strong>Tanzanian culture </strong>
              and experience the beauty of the country. <br />We can help interns organise
              safaris, trips to Zanzibar, climb Mount Kilimanjaro, visit local markets,
              and engage in other <strong>cultural activities.</strong>
            </Typography>
          </FadeInUp>
        </Box>
        {isDesktop ?
          <FadeInUp>
            <img src={LocationPictures} alt="Descriptive Alt Text" style={{ width: "100%" }} />
          </FadeInUp>
          :
          <FadeInUp>
            <Carousel indicators={false}>
              <Box>
                <img src={LocationPicture1} alt="Location 1" style={{ width: "100%", height: "240px", objectFit: "cover" }} />
              </Box>
              <Box>
                <img src={LocationPicture2} alt="Location 2" style={{ width: "100%", height: "240px", objectFit: "cover" }} />
              </Box>
              <Box>
                <img src={LocationPicture3} alt="Location 3" style={{ width: "100%", height: "240px", objectFit: "cover" }} />
              </Box>
              <Box>
                <img src={LocationPicture4} alt="Location 4" style={{ width: "100%", height: "240px", objectFit: "cover" }} />
              </Box>
            </Carousel>
          </FadeInUp>
        }
      </Box>

      <LandingReviews />
      <ContactForm/>
    </>
  );
};

export default LandingPage;
