import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FadeInUp from "bundles/common/utils/FadeInUp";
import { Box, Typography, useMediaQuery, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";

import Medicine from "images/medicine.svg";
import Dentistry from "images/dentistry.svg";
import Physiotherapy from "images/physio.svg";
import Social from "images/world-small.svg";
import ArrowTopRight from "images/arrow-top-right.svg";

const icons = {
  Medicine: Medicine,
  Dentistry: Dentistry,
  Physiotherapy: Physiotherapy,
  Social: Social,
};

const ProgramCard = ({ title, code, description, isDesktop, link }) => (
  <FadeInUp style={{ width: isDesktop ? "312px" : "60vw" }}>
    <Link to={link} style={{ textDecoration: "none", color: "inherit" }}>
      <Box
        sx={{
          position: "relative",
          flex: "0 0 auto",
          border: "1px solid #8B9AB8",
          borderRadius: "16px",
          padding: "20px 26px",
          width: "100%",
          height: "182px",
          marginBottom: 2,
          boxSizing: "border-box",
          transition: "transform 0.3s",
          ":hover": {
            transform: "scale(1.1)",
            cursor: "pointer"
          }
        }}
      >
        {/* Icon in a circle */}
        <Box
          sx={{
            position: "absolute",
            top: -25,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#E2EFFB",
            borderRadius: "50%",
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff"
          }}
        >
          <img src={icons[code]} alt={title} />
        </Box>

        {/* Title */}
        <Typography variant="h6" align="center" style={{ fontWeight: "bold", fontSize: "24px", marginTop: "16px", marginBottom: "16px" }}>
          {title}
        </Typography>

        {/* Description */}
        <Typography variant="body2" align="center" color="grey">
          {description}
        </Typography>

        {/* Oval Button with Arrow */}
        <Box
          sx={{
            position: "absolute",
            bottom: -15,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#F5F5F5",
            border: "1px solid #8B9AB8",
            borderRadius: "50px",
            width: "80px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={ArrowTopRight} alt="Arrow Top Right" />
        </Box>
      </Box>
    </Link>
  </FadeInUp>
);

const OurPrograms = () => {
  const theme = useTheme();
  const i18nLocale = useSelector(state => state.i18nLocale);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  if (isDesktop) {
    return (
      <>
        <Box sx={{ padding: 5, backgroundColor: "#F5F5F5" }}>
          <FadeInUp>
            <Typography variant="h2" align="center" style={{ fontSize: "54px", marginTop: "24px", marginBottom: "56px" }}>
              Our <strong>programs</strong>
            </Typography>
          </FadeInUp>

          <Grid container spacing={6} sx={{ marginBottom: 4, justifyContent: "center" }}>
            <Grid item xs={12} sm={6} md={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ProgramCard
                title="Medical and Nursing"
                code="Medicine"
                description="To gain hands-on experience and clinical knowledge"
                isDesktop={isDesktop}
                link={`/${i18nLocale}/career_fields/medical_and_nursing`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ProgramCard
                title="Dentistry"
                code="Dentistry"
                description="To enhance your ability to diagnose and treat various dental conditions"
                isDesktop={isDesktop}
                link={`/${i18nLocale}/career_fields/dentistry`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ProgramCard
                title="Physiotherapy"
                code="Physiotherapy"
                description="To implement your knowledge in a real-world setting"
                isDesktop={isDesktop}
                link={`/${i18nLocale}/career_fields/physiotherapy`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ProgramCard
                title="Teaching & Social Work"
                code="Social"
                description="To gain experience and contribute to community progress"
                isDesktop={isDesktop}
                link={`/${i18nLocale}/career_fields/volunteering`}
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ padding: 5, backgroundColor: "#F5F5F5" }}>
          <Typography variant="h2" align="left" style={{ fontSize: "38px", marginTop: "24px", marginBottom: "56px" }}>
            Our <br /> <strong><em>programs</em></strong>
          </Typography>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center-align items on the cross-axis
            justifyContent: "flex-start", // Align items to the start on the main-axi
            gap: "32px",
            width: "100%",
            marginBottom: "24px",
            pt: "40px",
            pb: "16px",
            textDecoration: "none",
            color: "black"
          }}>
            <ProgramCard
              title="Medical and Nursing"
              code="Medicine"
              description="To gain hands-on experience and clinical knowledge"
              isDesktop={isDesktop}
              link={`/${i18nLocale}/career_fields/medical_and_nursing`}
            />
            <ProgramCard
              title="Dentistry"
              code="Dentistry"
              description="To enhance your ability to diagnose and treat various dental conditions"
              isDesktop={isDesktop}
              link={`/${i18nLocale}/career_fields/dentistry`}
            />
            <ProgramCard
              title="Physiotherapy"
              code="Physiotherapy"
              description="To implement your knowledge in a real-world setting"
              isDesktop={isDesktop}
              link={`/${i18nLocale}/career_fields/physiotherapy`}
            />
            <ProgramCard
              title="Teaching & Social Work"
              code="Social"
              description="To gain experience and contribute to community progress"
              isDesktop={isDesktop}
              link={`/${i18nLocale}/career_fields/volunteering`}
            />
          </Box>
        </Box>
      </>
    );
  }
};

export default OurPrograms;
