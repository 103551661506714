import React from "react";
import FadeInUp from "bundles/common/utils/FadeInUp";
import { useMediaQuery, useTheme } from "@mui/material";

const PageTitle = ({ text }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const titleStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    marginTop: "32px",
    justifyContent: isDesktop ? "flex-start" : "left",
  };

  const textStyle = {
    fontWeight: "bold",
    marginRight: isDesktop ? "12rem" : "0",
    marginLeft: isDesktop ? "8rem" : "2rem",
    fontSize: isDesktop ? "68px" : "48px",
  };

  const barStyle = {
    flex: 1,
    height: "2px",
    backgroundColor: "#333",
    display: isDesktop ? "block" : "none",
  };

  return (
    <FadeInUp style={titleStyle}>
      <h1 style={textStyle} dangerouslySetInnerHTML={{ __html: text }} />
      <div style={barStyle}></div>
    </FadeInUp>
  );
};

export default PageTitle;
