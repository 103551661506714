import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import CarouselReviewItem from "bundles/Main/components/CarouselReviewItem";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Reviewer1 from "images/LandingPage/scaled/reviewer1_1280.webp";
import Reviewer2 from "images/LandingPage/scaled/reviewer2_1280.webp";
import Reviewer3 from "images/LandingPage/scaled/reviewer3_1280.webp";
import Reviewer4 from "images/LandingPage/scaled/reviewer4_1280.webp";
import Reviewer5 from "images/LandingPage/scaled/reviewer5_1280.webp";
import Reviewer6 from "images/LandingPage/scaled/reviewer6_1280.webp";
import Reviewer7 from "images/LandingPage/scaled/reviewer7_1280.webp";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  carouselGrid: {
    margin: "24px 0px !important",
  },
  avatar: {
    width: theme.spacing(35),
    height: theme.spacing(35),
  },
  reverseGrid: {
    flexDirection: "row-reverse",
  },
}));

const LandingReviews = () => {
  const classes = useStyles();
  const theme = useTheme();

  const reviews = [
    {
      key: 1,
      text: "Tanzania is the perfect place to start your discovery of Africa, and HealthMission is the ideal organization to structure your medical adventure. I spent two months in Arusha doing clinical training in busy hospitals, where I practiced hands-on medicine under supervision. The staff at Mount Meru were patient and supportive, and the comfortable house and delicious meals balanced the intense medical experience. I highly recommend HealthMission Africa for anyone looking to broaden their horizons and enhance their healthcare skills.",
      name: "Malena Grau",
      date: "April 2024",
      position: "5th year medical student",
      picture: Reviewer1,
      picturesmall: Reviewer1,
      alt: "",
    },
    {
      key: 2,
      text: "HealthMission Africa... Where do I start? My 22 days were amazing and unforgettable, but not long enough. The hospital felt like home with welcoming people and safe, beautiful accommodation. The food was tasty, and the security was excellent. Thank you for helping me pursue my dreams and providing a place to heal and reconnect. In just three weeks, I gained incredible knowledge and experience that I couldn't have imagined. Nakupenda Tanzania. Asante Sana.",
      name: "Caterina Alves",
      date: "January 2024",
      position: "4th year nursing student",
      picture: Reviewer2,
      picturesmall: Reviewer2,
      alt: "",
    },
    {
      key: 3,
      text: "I wholeheartedly recommend HealthMission Africa for medical students. Based in Arusha, Tanzania, this program offers an enriching blend of hands-on medical practice and cultural immersion. During my time at Arusha Hospital, I gained invaluable medical experience, supported by the incredibly kind and passionate staff who made us feel at home. The beauty of Tanzania’s landscapes added to the transformative experience, making it truly unforgettable. This program not only enhances medical skills but also opens your heart to the warmth and hospitality of the Tanzanian people.",
      name: "Dimitra Papagelopoulos",
      date: "July 2023",
      position: "4th year Medical Student",
      picture: Reviewer3,
      picturesmall: Reviewer3,
      alt: "",
    },
    // {
    //   key: 4,
    //   text: "Tanzania is the perfect place to start your discovery of Africa, and HealthMission is the ideal organization to structure your medical adventure. I spent two months in Arusha doing clinical training in busy hospitals, where I practiced hands-on medicine under supervision. The staff at Mount Meru were patient and supportive, and the comfortable house and delicious meals balanced the intense medical experience. I highly recommend HealthMission Africa for anyone looking to broaden their horizons and enhance their healthcare skills.",
    //   name: "Daniel Karni",
    //   date: "July 2023",
    //   position: "3rd year Dentistry Student",
    //   picture: Reviewer4,
    //   picturesmall: Reviewer4,
    //   alt: "",
    // },
    {
      key: 5,
      text: "My adventure with HealthMission was heartwarming. The experience that I will never forget has taught me enormously, both medically and non-medically. Thanks to HealthMission we had the opportunity to contribute a lot to the hospital and also to feel at home in Arusha. The contacts on site have a place in my heart. I will return next year with more medical knowledge to have an even greater impact! I’m very thankful for all the opportunities.",
      name: "Héloïse Couronné",
      date: "September 2023",
      position: "3rd year medical student",
      picture: Reviewer5,
      picturesmall: Reviewer5,
      alt: "",
    },
    {
      key: 6,
      text: "My two-month clinical training in Tanzania with HealthMission Africa was extraordinary. Based in Arusha, I trained in Pediatrics, Dermatology, and Emergency Medicine at Mount Meru Hospital, where the supportive staff helped me improve my skills. Comfortable accommodations and delicious meals made the intense days manageable. HealthMission Africa also organized amazing excursions to explore Tanzania's beauty. I highly recommend HealthMission Africa for a transformative experience that broadens horizons and enhances medical skills.",
      name: "Caroline Sommerfeld",
      date: "March 2024",
      position: "5th year Medical Student",
      picture: Reviewer6,
      picturesmall: Reviewer6,
      alt: "",
    },
    {
      key: 7,
      text: "My time with HealthMission Africa in Arusha was truly eye-opening and left a lasting impact on my view of medicine and the world. The internship allowed us to learn and contribute meaningfully to the community through hands-on medical procedures and patient interactions. Adapting to Tanzania's unique healthcare environment was inspiring, broadening my medical knowledge and boosting my confidence. This experience ignited my passion for compassionate healthcare and personal growth. If you're seeking a meaningful volunteering experience, I highly recommend HealthMission Africa.",
      name: "Eleni Vissaraki",
      date: "July 2023",
      position: "Medical Doctor",
      picture: Reviewer7,
      picturesmall: Reviewer7,
      alt: "",
    },
  ];

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid item xs={12} className={classes.carouselGrid}>
      <Carousel
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        navButtonsProps={{
          style: {
            backgroundColor: "transparent",
            color: "grey",
            opacity: isDesktop ? 1 : 0,
            marginRight: isDesktop ? "200px" : "0px",
            marginLeft: isDesktop ? "200px" : "0px",
          },
        }}
        animation={"slide"}
        duration={500}
        autoPlay={false}
      >
        {reviews.map((review, index) => (
          <CarouselReviewItem carouselItem={review} key={review.key} index={index} />
        ))}
      </Carousel>
    </Grid>
  );
};

export default LandingReviews;
