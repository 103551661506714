import React from "react";
import SectorPage from "./SectorPage";
import Cover from "images/CareerFields/scaled/dentistry-cover_2560.webp";
import Icon1 from "images/world.svg";
import Icon2 from "images/dentistry.svg";
import Icon3 from "images/hospital.svg";
import Icon4 from "images/house.svg";
import Image1 from "images/dentistry-image1.png";
import Image2 from "images/dentistry-image2.png";

const intro = (
  <>
    <strong>Are you a dentistry student or professional seeking a unique learning experience in Tanzania ? </strong>
    Look no further than HealthMission Africa's dentistry internship program, where you can gain hands-on clinical experience in a vibrant healthcare setting.
  </>
);

const bulletPoints = [
  { icon: Icon1, text: "Practical dentistry experience abroad" },
  { icon: Icon2, text: "Diverse dental case exposure" },
  { icon: Icon3, text: "Insights into healthcare challenges" },
  { icon: Icon4, text: "Comprehensive package with accommodation and food" }
];

const firstText = <p><strong>As a participant in our program,</strong> you will have the opportunity to work in the field of dentistry at <strong>renowned hospitals</strong> in Arusha, Tanzania. This invaluable experience will allow you to apply your <strong>knowledge</strong> in a <strong>practical setting</strong> and develop essential skills under the guidance of experienced dental professionals.<br /><br />
  In the Department of Dentistry, you will encounter a diverse range of dental cases that you may not frequently see in your home country. This exposure to different patient demographics and oral health conditions will broaden your clinical expertise and enhance your ability to diagnose and treat various dental issues.<br /><br />
  Tanzania's healthcare system presents unique challenges due to limited resources and funding. By immersing yourself in this environment, you will gain a deeper understanding of the complexities and resourcefulness required to provide dental care in underserved communities. This experience will equip you with adaptability and resilience, essential qualities for your future dental career.
</p>;

const secondText = <p>As part of our <strong>comprehensive package</strong>, we offer the <strong>accommodation</strong> (with security), daily <strong>breakfast</strong>, <strong>dinner</strong> on weekdays, an <strong>orientation day</strong>, <strong>airport pick-up</strong>, and <strong>hospital placement assistance</strong>.<br /><br /><br />
  But it’s not just about the clinical experience. Our volunteers also have the opportunity to immerse themselves in Tanzanian culture and experience the beauty of the country. We offer assistance in organising activities such as safaris, trips to Zanzibar, Mount Kilimanjaro climb, hot springs day trips, local markets visits, and other cultural activities.<br /><br /><br />
  By combining clinical skills development with cultural experiences, HealthMission Africa offers a comprehensive, immersive program that is unmatched in its scope and impact. Join us and experience the adventure of a lifetime while making a positive impact on the lives of others.
</p>;

const Dentistry = () => {
  return (
    <SectorPage
      cover={Cover}
      title={"Dentistry"}
      intro={intro}
      bulletPoints={bulletPoints}
      textOne={firstText}
      textTwo={secondText}
      imageOne={Image1}
      imageTwo={Image2}
    />
  );
};

export default Dentistry;
