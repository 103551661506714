import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";

const FadeInUp = ({ children, style }) => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1
      }
    );

    observer.observe(ref.current);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  const props = useSpring({
    to: { opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(30px)" },
    from: { opacity: 0, transform: "translateY(30px)" },
    config: { duration: 800 }
  });

  return <animated.div style={{ ...props, ...style }} ref={ref}>{children}</animated.div>;
};

export default FadeInUp;
