import React from "react";
import { Provider } from "react-redux";
import appStore from "bundles/common/store/appStore";
import HealthMissionAfricaTheme from "bundles/Layouts/HealthMissionAfricaTheme";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { styled } from "@mui/styles";
import MainContainer from "bundles/Main/container/MainContainer";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-info": {
    backgroundColor: "#2A334E",
  },
}));

const MainApp = (props, railsContext) => {
  const AppContent = () => {
    return (
      <Provider store={appStore(Object.assign(railsContext, props))}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          Components={{
            info: StyledMaterialDesignContent,
          }}
        >
          <HealthMissionAfricaTheme>
            <MainContainer {...props} />
          </HealthMissionAfricaTheme>
        </SnackbarProvider>
      </Provider>
    );
  };

  return <AppContent />;
};

export default MainApp;
