import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Quotes from "images/quotes.svg";
import { useMediaQuery, useTheme } from "@mui/material";

const CarouselReviewItem = ({ carouselItem, index }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const reverse = index % 2 === 1;

  return (
    <Grid
      container
      spacing={4}
      alignItems="center"
      direction={isDesktop && reverse ? "row-reverse" : "row"}
      justifyContent="center"
      style={{ height: isDesktop ? "560px" : "568px" }}
    >
      <Grid item xs={12} md={6} style={{ paddingTop: 0, display: 'flex', justifyContent: reverse ? 'flex-start' : 'flex-end' }}>
        <Box
          textAlign="center"
          style={{ width: isDesktop ? "380px" : "100%", marginLeft: reverse ? 'unset' : 'auto', marginRight: reverse ? 'auto' : 'unset' }}
        >
          <img
            src={Quotes}
            alt="Quotes"
            style={{ width: "50px", height: "50px" }}
          />
          <Typography
            variant="h5"
            style={{
              fontStyle: "italic",
              marginTop: "30px",
              marginBottom: "30px",
              fontSize: isDesktop ? "18px" : "16px",
              padding: isDesktop ? "unset" : "20px",
            }}
          >
            {carouselItem.text}
          </Typography>
          {!isDesktop && (
            <Box display="flex" alignItems="center" justifyContent="center" marginTop="20px">
              <img
                src={carouselItem.picture}
                srcSet={`${carouselItem.picture} 1x, ${carouselItem.picturesmall} 0.5x`}
                alt={carouselItem.alt}
                style={{
                  borderRadius: "50%",
                  height: "60px",
                  width: "60px",
                  marginRight: "12px",
                }}
              />
              <Box>
                <Typography variant="h5" style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {carouselItem.name} - {carouselItem.date}
                </Typography>
                <Typography
                  variant="h7"
                  style={{ fontSize: isDesktop ? "14px" : "12px" }}
                >
                  {carouselItem.position}
                </Typography>
              </Box>
            </Box>
          )}
          {isDesktop &&
            <>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginTop: "20px", fontSize: "18px" }}
              >
                {carouselItem.name} - {carouselItem.date}
              </Typography>
              <Typography
                variant="h7"
                style={{ fontSize: isDesktop ? "14px" : "12px" }}
              >
                {carouselItem.position}
              </Typography>
            </>
          }
        </Box>
      </Grid>
      {isDesktop && (
        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingTop: 0, display: 'flex', justifyContent: reverse ? 'flex-end' : 'flex-start', paddingLeft: reverse ? "unset" : "100px", paddingRight: reverse ? "100px" : "unset" }}
        >
          <img
            src={carouselItem.picture}
            srcSet={`${carouselItem.picture} 1x, ${carouselItem.picturesmall} 0.5x`}
            alt={carouselItem.alt}
            style={{
              borderRadius: "50%",
              height: "260px",
              width: "260px",
              marginTop: "40px",
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CarouselReviewItem;
