import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@mui/styles";
import { Typography, Box, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ApplyButton from "buttons/apply.svg";

import Carousel1 from "images/LandingPage/scaled/landing-carousel-9_2560.webp";
import Carousel2 from "images/LandingPage/scaled/landing-carousel-8_2560.webp";
import Carousel3 from "images/LandingPage/scaled/landing-carousel-7_2560.webp";
import Carousel4 from "images/LandingPage/scaled/landing-carousel-2_2560.webp";

import MobileCarousel1 from "images/LandingPage/scaled/landing-carousel-6-mobile_2560.webp";
import MobileCarousel2 from "images/LandingPage/scaled/landing-carousel-1_2560.webp";
import MobileCarousel3 from "images/LandingPage/landing-carousel-2-mobile.png";
import MobileCarousel4 from "images/LandingPage/landing-carousel-5-mobile.png";
import MobileCarousel5 from "images/LandingPage/landing-carousel-4-mobile.png";

const useStyles = makeStyles({
  arrow: {
    color: "white",
    fontSize: "2rem",
  },
  indicatorContainer: {
    position: "absolute",
    bottom: "10px",
    zIndex: 2,
  },
  navButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    outline: "none",
    padding: 0,
  },
  chevronImg: {
    width: "24px",
    height: "24px",
  },
});

const CarouselComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const i18nLocale = useSelector(state => state.i18nLocale);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [applyHovered, setAppliedHovered] = useState(false);
  const images = [Carousel1, Carousel2, Carousel3, Carousel4];
  const mobileImages = [MobileCarousel1, MobileCarousel2, MobileCarousel3, MobileCarousel4, MobileCarousel5];

  return (
    <Box
      style={{
        height: "100vh",
        backgroundImage: isDesktop ? `url(${images[0]})` : `url(${mobileImages[0]})`,
        backgroundSize: isDesktop ? "cover" : "contain",
        backgroundPosition: "center",
        position: "relative",
        maxWidth: isDesktop ? undefined : "100%",
      }}
    >
      <Box
        style={{
          content: "\"\"",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      ></Box>
      <Carousel
        autoPlay
        interval={4000}
        indicators={isDesktop}
        navButtonsAlwaysVisible={isDesktop}
        NextIcon={isDesktop && <ArrowForwardIosIcon style={{ fontSize: "2rem" }} />}
        PrevIcon={isDesktop && <ArrowBackIosIcon style={{ fontSize: "2rem" }} />}
        navButtonsProps={isDesktop ? {
          style: {
            backgroundColor: "transparent",
            opacity: 1,
            marginRight: "40px",
            marginLeft: "40px",
          },
        } : undefined}
        indicatorContainerProps={isDesktop ? {
          className: classes.indicatorContainer,
        } : undefined}
      >
        {(isDesktop ? images : mobileImages).map((image, index) => (
          <Box
            key={index}
            style={{
              height: "100vh",
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            <Box
              style={{
                content: "\"\"",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: "rgba(0, 0, 0, 0.32)",
              }}
            ></Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems={isDesktop ? "flex-start" : "center"}
              height="100%"
              position="relative"
              width={isDesktop ? "50%" : "75%"}
              mr={isDesktop ? "50px" : "30px"}
              ml={isDesktop ? "140px" : "40px"}
            >
              {!isDesktop &&
                <Typography variant="h1" style={{ fontSize: "20px", color: "white", marginTop: "2rem" }}>
                  <strong>HealthMission Africa</strong>
                </Typography>
              }
              <Box mt={isDesktop ? "120px" : "100px"}>
                <Typography variant="h2" align="left" style={{ fontSize: isDesktop ? "56px" : "28px", color: "white", marginBottom: isDesktop ? "48px" : "24px" }}>
                  <strong>Join us</strong> and <strong>experience</strong> the adventure while making a <em>positive impact on the lives of others.</em>
                </Typography>
                <Link to={`/${i18nLocale}/apply`} style={{ textDecoration: "none", color: "inherit" }}>
                  <img
                    src={ApplyButton}
                    alt="Apply"
                    style={{
                      cursor: "pointer",
                      width: isDesktop ? undefined : "180px",
                      transform: applyHovered ? "scale(1.1)" : "scale(1)",
                      transition: "all 0.3s ease",
                    }}
                    onMouseEnter={() => setAppliedHovered(true)}
                    onMouseLeave={() => setAppliedHovered(false)}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default CarouselComponent;
