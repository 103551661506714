import React from "react";
import { useTheme } from "@mui/styles";
import { Typography, Box, useMediaQuery } from "@mui/material";

import FadeInUp from "bundles/common/utils/FadeInUp";

import GroupPicture from "images/LandingPage/scaled/group-picture_2560.webp";
import MobileGroupPicture from "images/LandingPage/scaled/group-picture_2560.webp";

const Welcome = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Box textAlign="center" bgcolor="white" p={isDesktop ? 4 : 2} pl={isDesktop ? "220px" : "40px"} pr={isDesktop ? "220px" : "40px"}>
        <FadeInUp>
          <Typography variant="h2" style={{
            fontSize: isDesktop ? "44px" : "36px",
            marginTop: isDesktop ? "86px" : "48px",
            marginBottom: isDesktop ? "48px" : "24px"
          }}>
            Welcome to <strong>HealthMission Africa,</strong>
          </Typography>
        </FadeInUp>
        <FadeInUp>
          <Typography variant="body1" style={{
            fontSize: isDesktop ? "24px" : "18px",
            marginTop: isDesktop ? "24px" : "12px",
            marginBottom: isDesktop ? "86px" : "48px"
          }}>
            The medical volunteering/internship company offering unparalleled opportunities
            to healthcare professionals and students in <strong>Tanzania</strong>. We are proud to provide an
            <strong> immersive experience</strong> that combines clinical skills development with community
            outreach programs that address the <strong>healthcare needs</strong> of local populations.
          </Typography>
        </FadeInUp>
      </Box>

      <FadeInUp>
        <Box style={{
          position: "relative",
          display: "inline-block",
          width: "100%",
          maxHeight: "720px",
          overflow: "hidden",
        }}>
          <img
            src={(isDesktop ? GroupPicture : MobileGroupPicture)}
            alt="Group"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              position: "relative",
              zIndex: 1
            }}
          />
          <Box style={{
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 2
          }}>
          </Box>
        </Box>
      </FadeInUp>
    </>
  );
};

export default Welcome;
