import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box, Grid, Typography, List, ListItem, ListItemIcon } from "@mui/material";
import Title from "bundles/Main/components/Title";
import FadeInUp from "bundles/common/utils/FadeInUp";
import ContactForm from "bundles/Main/components/ContactForm";
import Founders from "images/4-founders.png";
import Greg from "images/greg-picture1.png";
import Alex from "images/alex-picture.png";
import Noel from "images/noel-picture.png";
import Leonidas from "images/leonidas-picture.png";

const teamMembers = [
  {
    image: Greg,
    name: "Greg Rozenberg",
    role: "Cofounder & CEO",
    bulletPoints: ["Sixth year medical student at European University of Cyprus", "Born and raised in Brussels, Belgium", "Co-founder of HealthMission Africa and Health Community Outreach Program",
      "CEO of HealthMission Africa", "Oversees all organizational departments and ensure they work cohesively towards our shared goals"]
  },
  {
    image: Alex,
    name: "Dr. Alex Ruben",
    role: "Cofounder & COO",
    bulletPoints: ["Medical officer", "Born and raised in Arusha, Tanzania", "Graduated from KAM COLLEGE OF HEALTH SCIENCES in Dar Es Salaam, where he studied clinical medicine",
      "Doctor in charge of the Perfect Dispensary in Arusha, Tanzania", "Co-founder of HealthMission Africa and Health Community Outreach Program", "Coordinator and supervisor of interns and volunteers in Tanzania", "Consults and treats patients during community outreach projects"]
  },
  {
    image: Noel,
    name: "Dr. Noel Kivuyo",
    role: "Cofounder & Clinical Supervisor",
    bulletPoints: ["Medical Doctor (MD)", "Born and raised in Arusha, Tanzania", "Graduated from CUHAS-BUGANDO University in Mwanza, where he studied medicine",
      "Works in the Emergency Department and ICU at Mount Meru Regional Referral Hospital, Arusha", "Co-founder of HealthMission Africa and Health Community Outreach Program", "Supervises interns and volunteers before and during their hospital internships.", "Consults and treats patients during community outreach projects"]
  },
  {
    image: Leonidas,
    name: "Dr. Leonidas Bousdoukos",
    role: "Cofounder & Volunteer and Outreach Coordinator",
    bulletPoints: ["Medical Doctor (MD)", "Born and raised in Hamburg, Germany", "Currently works as a cardiac surgery resident at the university medical-center in Hamburg, Germany",
      "Co-founder of HealthMission Africa and Health Community Outreach Program", "Planning process for selecting doctors who participate in our community outreach projects", "Supervises HCOP’s medical supply list and diagnostic devices", "Oversees the medical preparation of interns and volunteers before their internships."]
  }
];

const MemberInfo = ({ image, name, role, bulletPoints, reverse }) => (
  <Grid container
    ml={reverse ? -4 : 4}
    spacing={2}
    direction={reverse ? "row-reverse" : "row"}
    alignItems="center"
    justifyContent="center"
    sx={{
      maxWidth: "1200px",
      margin: "auto",
      marginBottom: "32px",
      width: "100%"
    }}>
    <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <FadeInUp>
        <img src={image} alt={name} style={{ width: "100%", height: "auto" }} />
      </FadeInUp>
    </Grid>
    <Grid item xs={12} md={8} style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: reverse ? "32px" : "32px",
      paddingRight: reverse ? "32px" : "0",
      overflow: "auto",
      maxHeight: "100%",
      maxWidth: "100%"
    }}>
      <FadeInUp>
        <Typography variant="h3" style={{ marginBottom: "16px", fontSize: "22px" }}><strong>{name}</strong></Typography>
      </FadeInUp>
      <FadeInUp>
        <Typography variant="h5" style={{fontSize: "18px" }}>{role}</Typography>
      </FadeInUp>
      <List>
        {bulletPoints.map((point, index) => (
          <FadeInUp key={ index }>
            <ListItem style={{ fontSize: "16px" }}>
              <ListItemIcon sx={{ minWidth: "32px" }}>•</ListItemIcon>
              {point}
            </ListItem>
          </FadeInUp>
        ))}
      </List>
    </Grid>
  </Grid>
);


const AboutUs = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const title = "<span style='font-weight: 400;'>Meet</span> <span style='font-weight: 800;'><em>the Team<em/></span>";

  return (
    <Box style={{ width: "100%" }}>
      <Title text={title} />
      <FadeInUp>
        <Box sx={{ maxWidth: "80%", margin: "32px auto" }}>
          <Grid container spacing={isDesktop ? 4 : 2} alignItems="center">
            <Grid item xs={12} md={6}>
              <h2>How it all started...</h2>
              {!isDesktop &&
                <FadeInUp>
                  <img src={Founders} alt="Founders" style={{ width: "100%", height: "auto", marginTop: "24px" }} />
                </FadeInUp>
              }
              <Typography sx={{ mt: 6, fontSize: "16px !important" }}>
                The foundations of HealthMission Africa and the Health Community Outreach Program were laid during a profound experience in Tanzania, in 2022. At the time, Greg and Leo, both medical students from the European University of Cyprus, were doing an internship in the emergency department at Mount Meru Regional Referral Hospital where they witnessed firsthand how challenging access to medical care could be for those living in poverty.<br /><br />
                Alongside Noel, a dedicated local doctor, and Alex, who operated a dispensary in Arusha, they initiated a community outreach project to address these healthcare deficiencies. Their collaboration led to a successful two-day medical camp in nearby villages, where they treated over 600 patients, providing essential care and medications.<br /><br />
                Once Greg and Leo returned to Cyprus, where they studied, they found their peers showing interest in participating in similar experiences. Sensing the opportunity, Greg, Leo, Noel, and Alex realized they could organize more such programs, and together decided to establish HealthMission Africa and the Health Community Outreach Program.<br /><br />
                These organizations were designed to facilitate healthcare internships and outreach projects, allowing more students to gain firsthand experience while significantly impacting the lives of those in underserved communities.
              </Typography>
            </Grid>
            {isDesktop &&
              <Grid item xs={12} md={6}>
                <FadeInUp>
                  <img src={Founders} alt="Founders" style={{ width: "100%", height: "auto" }} />
                </FadeInUp>
              </Grid>
            }
          </Grid>
        </Box>
      </FadeInUp>
      {teamMembers.map((member, index) => (
        <MemberInfo key={index} {...member} reverse={index % 2 !== 0} />
      ))}
      <FadeInUp>
        <Typography variant="h3" style={{ margin: isDesktop ? "16px 120px" : "16px 32px", fontSize: isDesktop ? "16px" : "14px" }}>
          *HealthMission Africa, registered on September 12, 2023, is a Cypriot company with its office located at 20 Spyrou Kyprianou, CHAPO CENTRAL, Flat/Office 201, 1075, Nicosia, Cyprus.
        </Typography>
      </FadeInUp>
      <ContactForm />
    </Box>
  );
};

export default AboutUs;
