import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Container, useMediaQuery, Grid, Fade } from "@mui/material";
import FadeInUp from "bundles/common/utils/FadeInUp";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import ApplyButton from "buttons/apply-button-blue.svg";
import ContactForm from "bundles/Main/components/ContactForm";

const BulletPoint = ({ icon, text }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid item xs={12} sm={6} md={3} align="center">
      <FadeInUp style={{ position: "relative", mt: 5 }}>
        <Box
          sx={{
            position: "absolute",
            top: -60,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#E2EFFB",
            borderRadius: "50%",
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={icon} alt={text} style={{ width: "60%", height: "auto" }} />
        </Box>
        <Typography
          variant="h7"
          color="secondary"
          sx={{
            mt: 8,
            fontWeight: isDesktop ? "bold" : "",
            fontSize: "16px !important",
            textAlign: "center"
          }}
        >
          {text}
        </Typography>
      </FadeInUp>
    </Grid>
  );
};


const SectorPage = ({ cover, title, intro, bulletPoints, textOne, textTwo, textThree, imageOne, imageTwo, imageThree}) => {
  const theme = useTheme();
  const i18nLocale = useSelector(state => state.i18nLocale);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [applyHovered, setAppliedHovered] = useState(false);


  useEffect(() => {
    const coverImageElement = document.getElementById("cover-image");
    { isDesktop && coverImageElement &&
      window.scrollTo({ top: coverImageElement.offsetTop });
    }
  }, []);


  return (
    <Box>
      <Box
        id="cover-image"
        sx={{
          backgroundImage: `url(${cover})`,
          backgroundSize: "cover",
          backgroundPosition: "center 10%",
          color: "white",
          height: isDesktop ? "68vh" : "40vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-end",
          textAlign: "left",
          position: "relative",
          "&::before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.36)",
            zIndex: 1,
          },
          "& > *": {
            zIndex: 2,
          },
        }}
      >
        <Box id="this-button" style={{ position: "absolute", top: 40, left: isDesktop ? 80 : 20 }}>
          <Link to={`/${i18nLocale}/career_fields`} style={{ textDecoration: "none", color: "white", marginRight: "10px" }}>
            <Typography
              variant="h6"
              sx={{
                display: "inline",
                fontStyle: "italic",
                "&:hover": {
                  textDecoration: isDesktop ? "underline" : "",
                  textDecorationColor: isDesktop ? "white" : "",
                  textDecorationThickness: isDesktop ? "1px" : ""
                },
                fontSize: isDesktop ? "" : "40px !important"
              }}
            >
              ← {isDesktop ? "Career Fields" : ""}
            </Typography>
          </Link>
        </Box>
        <FadeInUp>
          <Typography
            variant="h2"
            sx={{
              ml: isDesktop ? 10 : 4,
              fontWeight: "bold",
              fontSize: "2.6rem !important",
              textTransform: "uppercase",
              fontStyle: "italic",
              color: "white",
            }}
          >
            {title}
          </Typography>
        </FadeInUp>
      </Box>


      <Box sx={{ py: 4, maxWidth: isDesktop ? "50%" : "90%", margin: isDesktop ? "" : "auto", textAlign: "left", ml: isDesktop ? 8 : "", mt: isDesktop ? 4 : "", mb: isDesktop ? 8 : "" }}>
        <Container>
          <FadeInUp>
            <Typography variant="h6" sx={{ fontSize: isDesktop ? "20px" : "18px !important" }}>{intro}</Typography>
          </FadeInUp>
        </Container>
      </Box>

      {isDesktop &&
        <Container sx={{ py: 4 }}>
          <Grid container spacing={2}>
            {bulletPoints.map((point, index) => (
              <BulletPoint key={index} icon={point.icon} text={point.text} />
            ))}
          </Grid>
        </Container>
      }

      {!isDesktop &&
        <Container sx={{ py: 4, mt: 2, mb: -12 }}>
          <Grid container spacing={2} sx={{ "& .MuiGrid-item": { marginBottom: 4 } }}>
            {bulletPoints.map((point, index) => (
              <Grid item xs={6} md={3} key={index}>
                <BulletPoint icon={point.icon} text={point.text} />
              </Grid>
            ))}
          </Grid>
        </Container>
      }

      <FadeInUp style={{ textAlign: "center", margin: "20px 0" }}>
        <Link to={`/${i18nLocale}/apply`} style={{ cursor: "pointer" }}>
          <img
            src={ApplyButton}
            alt="Apply"
            style={{
              width: isDesktop ? undefined : "140px",
              transform: applyHovered ? "scale(1.1)" : "scale(1)",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={() => setAppliedHovered(true)}
            onMouseLeave={() => setAppliedHovered(false)}
          />
        </Link>
      </FadeInUp>

      <Grid container>
        <Grid item xs={12} md={6} sx={{ backgroundColor: "white", display: "flex", alignItems: "center" }}>
          <FadeInUp>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              textAlign: "left",
              pl: isDesktop ? 12 : 0,
              pr: isDesktop ? 12 : 0,
              width: isDesktop ? "100%" : "80%",
              margin: isDesktop ? "" : "auto"
            }}>
              {textThree && imageThree &&
                <Box
                  component="h2"
                  sx={{
                    mt: isDesktop ? 0 : 6,
                  }}
                >
                  Teaching
                </Box>
              }
              <Typography sx={{ fontSize: "18px !important", margin: isDesktop ? "" : textThree && imageThree ? "" : "32px 0" }}>
                {textOne}
              </Typography>
            </Box>
          </FadeInUp>
        </Grid>
        <Grid item xs={12} md={6}>
          <FadeInUp sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={imageOne} alt="Image One" style={{ width: "100%", height: "auto" }} />
          </FadeInUp>
        </Grid>
        {isDesktop &&
          <Grid item xs={12} md={6}>
            <FadeInUp sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={imageTwo} alt="Image Two" style={{ width: "100%", height: "auto" }} />
            </FadeInUp>
          </Grid>
        }
        <Grid item xs={12} md={6} sx={{ backgroundColor: "white", display: "flex", alignItems: "center" }}>
          <FadeInUp>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              textAlign: "left",
              pl: isDesktop ? 12 : 0,
              pr: isDesktop ? 12 : 0,
              width: isDesktop ? "100%" : "80%",
              margin: isDesktop ? "" : "auto"
            }}>
              {textThree && imageThree &&
                <Box
                  component="h2"
                  sx={{
                    mt: isDesktop ? 0 : 6,
                  }}
                >
                  Social Work
                </Box>
              }
              <Typography sx={{ fontSize: "18px !important", margin: isDesktop ? "" : textThree && imageThree ? "" : "32px 0" }}>
                {textTwo}
              </Typography>
            </Box>
          </FadeInUp>
        </Grid>
        {!isDesktop &&
          <Grid item xs={12} md={6}>
            <FadeInUp sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={imageTwo} alt="Image Two" style={{ width: "100%", height: "auto" }} />
            </FadeInUp>
          </Grid>
        }
        {textThree && imageThree &&
          <>
            <Grid item xs={12} md={6} sx={{ backgroundColor: "white", display: "flex", alignItems: "center" }}>
              <FadeInUp>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  textAlign: "left",
                  pl: isDesktop ? 12 : 0,
                  pr: isDesktop ? 12 : 0,
                  width: isDesktop ? "100%" : "80%",
                  margin: isDesktop ? "" : "auto"
                }}>
                  <Typography sx={{ fontSize: "18px !important", margin: isDesktop ? "" : "32px 0" }}>
                    {textThree}
                  </Typography>
                </Box>
              </FadeInUp>
            </Grid>
            <Grid item xs={12} md={6}>
              <FadeInUp sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={imageThree} alt="Image Three" style={{ width: "100%", height: "auto" }} />
              </FadeInUp>
            </Grid>
          </>
        }
      </Grid>
      <ContactForm />
    </Box>
  );
};

export default SectorPage;
