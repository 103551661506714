import React, { useState } from "react";
import FadeInUp from "bundles/common/utils/FadeInUp";
import { Box, Button, Modal, IconButton } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import ArrowRightWhite from "images/arrow-right-white.svg";
import SeeMoreButton from "buttons/see-more.svg";

import Title from "bundles/Main/components/Title";
import ContactForm from "bundles/Main/components/ContactForm";

const Gallery = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [visibleImagesCount, setVisibleImagesCount] = useState(24);
  const [seeMoreHovered, setSeeMoreHovered] = useState(false);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const title = "<span style='font-weight: 400;'>Photo</span> <span style='font-weight: 800;'><em>Gallery</em></span>";

  const categories = {
    all: Array.from({ length: 147 }, (_, index) => index),
    medical : [
      3, 4, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 28,
      31, 39, 40, 41, 45, 52, 57, 58, 59, 60, 62, 63, 64, 67, 68, 69, 70, 71, 72,
      77, 78, 79, 80, 82, 83, 85, 86, 91, 93, 94, 95, 96, 97, 98, 99, 102, 103, 104, 106, 107,
      109, 110, 111, 112, 114, 115, 116, 117, 118, 119, 120, 121, 123, 124, 125,
      126, 127, 130, 132, 133, 134, 135, 136, 137, 138, 139, 140, 143, 145, 147
    ].map(num => num - 1),
    extra: [
      1, 2, 5, 8, 17, 25, 26, 27, 29, 30, 32, 33, 34, 35, 36, 37, 38, 42, 43, 44,
      46, 47, 48, 49, 50, 51, 53, 54, 55, 56, 61, 65, 66, 73, 74, 75, 76, 81,
      84, 87, 88, 89, 90, 92, 100, 101, 105, 108, 113, 122, 128, 129, 131,
      141, 142, 144, 146
    ].map(num => num - 1)
  };

  const getNextIndexes = (current, count = 3) => {
    const currentIndex = categories[selectedCategory].indexOf(current);
    const totalImages = categories[selectedCategory].length;
    return Array.from({ length: count }, (_, i) => categories[selectedCategory][(currentIndex + i + 1) % totalImages]);
  };

  const generateImagePath = (index, size) => {
    return `/gallery-pictures/${index}_${size}.webp`;
  };

  const handleOpen = (index) => {
    setOpen(true);
    setSelectedImage(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setImageOpacity(0);
    setTimeout(() => {
      setSelectedImage(prevIndex => {
        const currentIndex = categories[selectedCategory].indexOf(prevIndex);
        const nextIndex = (currentIndex + 1) % categories[selectedCategory].length;
        return categories[selectedCategory][nextIndex];
      });
      setImageOpacity(1);
    }, 160);
  };

  const handlePrevious = () => {
    setImageOpacity(0);
    setTimeout(() => {
      setSelectedImage(prevIndex => {
        const currentIndex = categories[selectedCategory].indexOf(prevIndex);
        const newIndex = (currentIndex - 1 + categories[selectedCategory].length) % categories[selectedCategory].length;
        return categories[selectedCategory][newIndex];
      });
      setImageOpacity(1);
    }, 160);
  };

  const handleSeeMore = () => {
    setVisibleImagesCount(prevCount => Math.min(prevCount + 24, categories[selectedCategory].length));
  };

  const modalContent = () => {
    const nextIndexes = getNextIndexes(selectedImage);

    return (
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "transparent",
          padding: 20,
          outline: "none",
          width: "36%",
          maxWidth: "1200px",
        }}
      >
        <IconButton
          style={{ position: "absolute", left: -96, top: "50%", transform: "translateY(-50%) rotate(180deg)" }}
          onClick={handlePrevious}
        >
          <img src={ArrowRightWhite} alt="Left Arrow" />
        </IconButton>

        <img
          src={generateImagePath(selectedImage + 1, 640)}
          srcSet={`
            ${generateImagePath(selectedImage + 1, 640)} 640w,
            ${generateImagePath(selectedImage + 1, 1280)} 1280w
          `}
          sizes="(max-width: 640px) 640px, (max-width: 1280px) 1280px, 2560px"
          alt={`Image ${selectedImage}`}
          style={{
            width: "500px",
            height: "500px",
            objectFit: "cover",
            opacity: imageOpacity,
            transition: "opacity 0.3s ease-in-out",
          }}
        />

        <IconButton style={{ position: "absolute", right: -96, top: "50%", transform: "translateY(-50%)" }} onClick={handleNext}>
          <img src={ArrowRightWhite} alt="Right Arrow" />
        </IconButton>

        <Box display="flex" justifyContent="center" marginTop={2}>
          {nextIndexes.map((index) => (
            <img
              key={index}
              src={generateImagePath(index + 1, 640)}
              alt={`Next Image ${index}`}
              style={{ width: "100px", height: "100px", objectFit: "cover", margin: "0 10px" }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const renderImages = () => {
    const imagesToRender = categories[selectedCategory].slice(0, visibleImagesCount);

    return imagesToRender.map((index) => (
      <FadeInUp
        key={index}
        style={{ width: isDesktop ? "26vw" : "400px", height: isDesktop ? "26vw" : "400px", margin: "10px", overflow: "hidden" }}
      >
        <img
          src={generateImagePath(index + 1, 640)}
          srcSet={`
            ${generateImagePath(index + 1, 640)} 640w,
            ${generateImagePath(index + 1, 1280)} 1280w
          `}
          sizes="(max-width: 640px) 640px, 100vw"
          alt={`Carousel ${index + 1}`}
          style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer" }}
          onClick={() => handleOpen(index)}
        />
      </FadeInUp>
    ));
  };

  return (
    <>
      <Box style={{ width: "100%", marginBottom: "16px" }}>
        <Title text={title} />
        <p style={{ paddingLeft: isDesktop ? "6rem" : "2rem", marginBottom: "2rem" }}>⚠️ This gallery contains graphic images of medical procedures that may be disturbing to some viewers.</p>
        <FadeInUp>
          <Box display="flex" justifyContent="center" marginBottom={2}>
            <Button
              variant={selectedCategory === "all" ? "contained" : "outlined"}
              onClick={() => setSelectedCategory("all")}
              style={{ marginRight: "8px" }}
              color="secondary"
            >
              All
            </Button>
            <Button
              variant={selectedCategory === "medical" ? "contained" : "outlined"}
              onClick={() => setSelectedCategory("medical")}
              style={{ marginRight: "8px" }}
              color="secondary"
            >
              Medical
            </Button>
            <Button
              variant={selectedCategory === "extra" ? "contained" : "outlined"}
              onClick={() => setSelectedCategory("extra")}
              color="secondary"
            >
              Extra
            </Button>
          </Box>
        </FadeInUp>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          style={{ marginRight: "6%", marginLeft: "6%" }}
        >
          {renderImages()}
        </Box>
        {visibleImagesCount < categories[selectedCategory].length && (
          <FadeInUp
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "32px 0",
              cursor: "pointer",
              transform: seeMoreHovered ? "scale(1.1)" : "scale(1)",
              transition: "all 0.3s ease",
            }}
          >
            <img
              src={SeeMoreButton}
              alt="See More"
              onClick={handleSeeMore}
              onMouseEnter={() => setSeeMoreHovered(true)}
              onMouseLeave={() => setSeeMoreHovered(false)}
            />
          </FadeInUp>
        )}
        {isDesktop &&
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ backdropFilter: "blur(1px)", backgroundColor: "rgba(0, 0, 0, 0.1)" }}
          >
            {modalContent()}
          </Modal>
        }
      </Box>
      <ContactForm />
    </>
  );
};

export default Gallery;
