import { configureStore } from "@reduxjs/toolkit";
import railsContextReducer from "../reducers/railsContextReducer";

const appStore = (railsProps) => {
  return configureStore({
    reducer: railsContextReducer,
    preloadedState: railsProps
  });
};

export default appStore;



// import { configureStore } from '@reduxjs/toolkit';
// import railsContextReducer from '../reducers/railsContextReducer';

// // Documentation for redux store api
// // https://github.com/shakacode/react_on_rails/blob/master/docs/api/redux-store-api.md
// const appStore = (railsProps) => {
//   return configureStore({
//     reducer: {
//       railsContext: railsContextReducer,
//     },
//     preloadedState: {
//       railsContext: railsProps
//     }
//   });
// };

// export default appStore;
