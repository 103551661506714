import React, { useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FadeInUp from "bundles/common/utils/FadeInUp";
import {
  Box,
  Button,
  InputBase,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import { BeatLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import sh from "bundles/common/utils/sh";
import validator from "validator";
import SubmitButton from "buttons/submit.svg";
import blueLogo from "images/blue-logo.svg";

const initialFormState = {
  values: {
    fullName: "",
    email: "",
    message: "",
  },
  errors: {},
  loading: false,
  attemptedSubmit: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
  case "SET_VALUES":
    return {
      ...state,
      values: {
        ...state.values,
        [action.payload.name]: action.payload.value,
      },
    };
  case "SET_LOADING":
    return {
      ...state,
      loading: action.payload,
    };
  case "SET_ATTEMPTED_SUBMIT":
    return {
      ...state,
      attemptedSubmit: true,
    };
  case "RESET_FORM":
    return initialFormState;
  default:
    return state;
  }
};

const ContactForm = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector(state => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const [{ values, loading }, dispatch] = useReducer(formReducer, initialFormState);
  const [submitHovered, setSubmitHovered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const linkStyle = {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "transparent !important",
      textDecoration: "underline",
    },
  };

  const buttonStyle = {
    textTransform: "none",
    color: theme.palette.secondary.main,
    fontSize: "1rem",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent !important",
      textDecoration: "underline",
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: "SET_VALUES", payload: { name, value } });
  };

  const validateForm = (values) => {
    let errors = {};

    if (!values.fullName.trim()) {
      errors.fullName = "Full name is required.";
    }

    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Email is not valid.";
    }

    if (!values.message.trim()) {
      errors.message = "A Message is required.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm(values);
    const isValid = Object.keys(formErrors).length === 0;

    if (isValid) {
      setIsSubmitting(true);
      dispatch({ type: "SET_LOADING", payload: true });
      sh.post("/contact", {
        authenticity_token: authenticityToken,
        lead_data: {
          name: values.fullName,
          email: values.email
        },
        question_data: {
          content: values.message,
        },
      })
        .then(() => {
          enqueueSnackbar("Message sent successfully. We will get back to you soon !", { variant: "success" });
          setTimeout(() => {
            setIsSubmitting(false);
            const reloadUrl = window.location.pathname;
            window.location.href = reloadUrl;
          }, 2400);
        })
        .catch(() => {
          enqueueSnackbar("Failed to sent message.", { variant: "error" });
          setIsSubmitting(false);
        })
        .finally(() => {
          dispatch({ type: "SET_LOADING", payload: false });
        });
    } else {
      dispatch({ type: "SET_ERRORS", payload: formErrors });
      enqueueSnackbar(Object.values(formErrors).join(" "), { variant: "error" });
    }
  };

  return (
    <>
      <Box id="contact-form" sx={{ bgcolor: "#F4F4F4", width: "100%", boxShadow: "none" }}>
        <FadeInUp>
          <Typography
            variant="h2"
            style={{
              color: theme.palette.secondary.main,
              textAlign: "center",
              fontSize: "36px",
              paddingTop: "48px",
            }}
          >
            Ask us <strong><em>anything!</em></strong>
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: theme.palette.secondary.main,
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            Fill in the form below or send us an email at{" "}
            <a
              href="mailto:contact@healthmissionafrica.com"
              style={{ color: theme.palette.secondary.main, fontWeight: 600, textDecoration: "none" }}
            >
              contact@healthmissionafrica.com
            </a>
          </Typography>
        </FadeInUp>
        <FadeInUp>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 6, pb: 6, px: { xs: 2, md: 40 } }}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <InputBase
                  fullWidth
                  placeholder="Full name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  sx={{
                    borderBottom: `2px solid ${theme.palette.secondary.main}`,
                    color: theme.palette.secondary.main,
                    textAlign: "center",
                    mb: 2
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputBase
                  fullWidth
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  sx={{
                    borderBottom: `2px solid ${theme.palette.secondary.main}`,
                    color: theme.palette.secondary.main,
                    textAlign: "center",
                    mb: 2
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputBase
                  fullWidth
                  placeholder="Type your message here..."
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  sx={{
                    borderBottom: `2px solid ${theme.palette.secondary.main}`,
                    color: theme.palette.secondary.main,
                    textAlign: "center",
                    mb: 2
                  }}
                  multiline
                  rows={1}
                />
              </Grid>
              <Grid item xs={12}>
                <Box textAlign="center" mt={2}>
                  {isSubmitting ?
                    <BeatLoader size={10} color="#173571" />
                    :
                    <img
                      src={SubmitButton}
                      alt="Submit"
                      onClick={!loading ? handleSubmit : undefined}
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        maxWidth: "160px",
                        transform: submitHovered ? "scale(1.1)" : "scale(1)",
                        transition: "all 0.3s ease",
                        opacity: loading ? 0.5 : 1,
                        pointerEvents: loading ? "none" : "auto"
                      }}
                      onMouseEnter={() => setSubmitHovered(true)}
                      onMouseLeave={() => setSubmitHovered(false)}
                    />
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </FadeInUp>
      </Box>
      <Box sx={{ bgcolor: "#F4F4F4", px: { xs: 2, md: 10 }, pb: 2 }}>
        <Grid container sx={{ width: "100%", margin: 0 }} justifyContent={{ xs: "center", md: "space-between" }} alignItems="center" spacing={2}>
          <div style={{display: "flex" }}>
            {/* Internal Links */}
            <FadeInUp>
              <Button component="a" href={`/${i18nLocale}/apply`} rel="noopener noreferrer" sx={buttonStyle}>
                Join Us
              </Button>
            </FadeInUp>
            <FadeInUp>
              <Button component="a" href={`/${i18nLocale}/about_us`} rel="noopener noreferrer" sx={buttonStyle}>
                About
              </Button>
            </FadeInUp>
            {/* External Links */}
            <FadeInUp>
              <Button component="a" href="https://www.linkedin.com/company/healthmission-africa/" target="_blank" rel="noopener noreferrer" sx={buttonStyle}>
                Linkedin
              </Button>
            </FadeInUp>
            <FadeInUp>
              <Button component="a" href="https://www.instagram.com/healthmissionafrica/" target="_blank" rel="noopener noreferrer" sx={buttonStyle}>
                Instagram
              </Button>
            </FadeInUp>
            {/* Logo */}
          </div>
          <div style={{ display: "flex" }}>
            <FadeInUp>
              <Link to={`/${i18nLocale}`} style={{ textDecoration: "none", color: "inherit" }}>
                <img src={blueLogo} alt="HealthMission Africa Logo" style={{ height: "50px", marginLeft: isDesktop ? theme.spacing(80) : theme.spacing(2) }} />
              </Link>
            </FadeInUp>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default ContactForm;
