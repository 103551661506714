import React from "react";
import FadeInUp from "bundles/common/utils/FadeInUp";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box, Typography, Grid } from "@mui/material";
import Image1 from "images/ngo-image1.png";
import Image2 from "images/ngo-image2.png";
import NGOLogo from "images/ngo-logo_1280.webp";
import ContactForm from "../components/ContactForm";
import Title from "bundles/Main/components/Title";

const NGO = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const title = "<span style='font-weight: 400;'>Our</span> <span style='font-weight: 800;'><em>NGO<em/></span>";

  return (
    <>
      <Title text={title} />
      <Grid container>
        <Grid item xs={12} md={6} sx={{
          display: "flex",
          maxWidth: "80%",
          mx: "auto"
        }}>
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <FadeInUp>
              <img src={NGOLogo} alt="NGO Logo" style={{ width: isDesktop? "50%": "100%", height: "auto" }} />
            </FadeInUp>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              textAlign: "left",
              pl: isDesktop? 12 : 0,
              pr: isDesktop ? 12 : 0,
              width: "100%",
              mt: 8
            }}>
              <Typography sx={{ fontSize: isDesktop? "18px": "16px !important" }}>
                <FadeInUp><span style={{ fontSize: "22px" }}><strong> Welcome to Health Community Outreach Program,</strong> an integral part of HealthMission Africa's mission to promote health equity and wellness in Tanzania.</span><br /><br /></FadeInUp>
                <FadeInUp>Our non-profit organization was founded on the principle that access to healthcare is a universal right, not a privilege. We believe that no one should be left behind because of their geographic location or socio-economic status. Our work is centered on addressing healthcare disparities and improving the overall well-being of the underserved communities in Tanzania.<br /><br /></FadeInUp>
                <FadeInUp>Our projects are multifaceted and encompass a wide range of healthcare initiatives. We are actively involved in community outreach programs where we conduct medical consultations, provide treatments, and offer education about preventable diseases. By focusing on prevention and health promotion, we aim to mitigate the impact of common health issues and equip individuals with the knowledge and tools to take control of their health.</FadeInUp>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ margin: isDesktop? "": "32px 0px" }}>
          <FadeInUp sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src="/gallery-pictures/71_1280.webp" alt="Image One" style={{ width: "100%", height: "auto" }} />
          </FadeInUp>
        </Grid>
        {isDesktop &&
          <Grid item xs={12} md={6}>
            <FadeInUp sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src="/gallery-pictures/91_1280.webp" alt="Image Two" style={{ width: "100%", height: "auto" }} />
            </FadeInUp>
          </Grid>
        }
        <Grid item xs={12} md={6} sx={{ maxWidth: "80%", margin: "auto", display: "flex", alignItems: "center" }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            textAlign: "left",
            pl: isDesktop ? 12 : 0,
            pr: isDesktop ? 12 : 0,
            width: "100%"
          }}>
            <Typography sx={{ fontSize: isDesktop ? "18px" : "16px !important" }}>
              <FadeInUp>We also work closely with international volunteers and interns who bring fresh perspectives and energy to our team. These individuals, many of whom are students and professionals from the healthcare field, contribute significantly to our projects. Not only do they gain hands-on experience in a unique healthcare setting, but they also participate actively in transforming the health landscape of the communities we serve.<br /><br /></FadeInUp>
              <FadeInUp>At the Health Community Outreach Program, we are dedicated to creating sustainable changes that can yield long-term health benefits for the Tanzanian communities. By integrating our efforts with local healthcare systems, and by promoting international cooperation, we strive to bridge healthcare gaps and create a healthier future for all.<br /><br /></FadeInUp>
              <FadeInUp>Join us in our mission and make a real, measurable difference in the lives of the Tanzanian people. We welcome all who share our passion for health equity and are eager to contribute to this meaningful cause. Your journey with us promises to be enriching, impactful, and life-changing.<br /><br /></FadeInUp>
            </Typography>
          </Box>
        </Grid>
        {!isDesktop &&
          <Grid item xs={12} md={6}>
            <FadeInUp sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src="/gallery-pictures/91_1280.webp" alt="Image Two" style={{ width: "100%", height: "auto" }} />
            </FadeInUp>
          </Grid>
        }
      </Grid>
      <Box sx={{margin: isDesktop ? "" : "24px 0"}}>
        <FadeInUp>
          <Typography variant="h3" style={{ margin: isDesktop ? "16px 120px" : "16px 32px", fontSize: isDesktop ? "16px" : "14px" }}>
            *The United Republic of Tanzania: Health Community Outreach Program was duly registered on April 4, 2023, under the Non-Governmental Organizations Act, 2002, with Registration No. 01NGO/R/4561.
          </Typography>
        </FadeInUp>
        <FadeInUp>
          <Typography variant="h3" style={{ margin: isDesktop ? "16px 120px" : "16px 32px", fontSize: isDesktop ? "16px" : "14px" }}>
            Account name: Health Community Outreach Program<br />
            Account number: 40810183192<br />
            Bank name: NMB PLC<br />
            Swift code: NMIBTZTZ<br />
            Address: P.O.BOX 3093, Arusha, Tanzania
          </Typography>
        </FadeInUp>
      </Box>
      <ContactForm/>
    </>
  );
};

export default NGO;
