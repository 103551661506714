// Let's assume you have a constant to change the current section
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";

export const initialState = {
  currentSection: 'home', // default value
};

const railsContextReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SECTION:
      return { ...state, currentSection: action.payload };
    default:
      return state;
  }
};

export default railsContextReducer;
