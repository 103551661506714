import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box, Typography, Grid } from "@mui/material";
import FadeInUp from "bundles/common/utils/FadeInUp";
import Carousel from "react-material-ui-carousel";
import Arusha from "images/house/scaled/arusha-picture_2560.webp";
import Carousel1 from "images/house/1.png";
import Carousel2 from "images/house/2.png";
import Carousel3 from "images/house/3.png";
import Carousel4 from "images/house/4.png";
import Carousel5 from "images/house/5.png";
import Carousel6 from "images/house/6.png";
import Carousel7 from "images/house/7.png";
import Carousel8 from "images/house/8.png";
import Carousel9 from "images/house/9.png";
import Carousel10 from "images/house/10.png";
import Carousel11 from "images/house/11.png";
import ArrowRight from "images/arrow-right.svg";
import ArrowRightMobile from "images/arrow-right-white.svg";
import Title from "bundles/Main/components/Title";
import ContactForm from "../components/ContactForm";

const images = [Carousel1, Carousel2, Carousel3, Carousel4, Carousel5, Carousel6, Carousel7, Carousel8, Carousel9, Carousel10, Carousel11];

const OurHouse = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const carouselDesktopItems = () => {
    const groupedImages = [];
    for (let i = 0; i < images.length; i += 3) {
      groupedImages.push(images.slice(i, i + 3));
    }

    return groupedImages.map((group, index) => (
      <Box key={index} display="flex" justifyContent="center">
        {group.map((image, idx) => (
          <img
            key={idx}
            src={image}
            alt={`Carousel ${index}-${idx}`}
            style={{ width: "400px", height: "509px", margin: "0 10px" }}
          />
        ))}
      </Box>
    ));
  };

  const carouselMobileItems = () => {
    return images.map((image, idx) => (
      <Box key={idx} display="flex" justifyContent="center">
        <img
          src={image}
          alt={`Carousel ${idx}`}
          style={{ width: "90%", height: "auto", margin: "0" }}
        />
      </Box>
    ));
  };

  const title = "<span style='font-weight: 400;'>Our</span> <span style='font-weight: 800;'><em>House<em/></span>";

  return (
    <>
      <Box style={{ width: "100%", marginBottom: isDesktop ? "48px" : "32px" }}>
        <Title text={title} />
        <Grid container mb={isDesktop ? 16 : 4} mt={-4}>
          <Grid item xs={12} md={6} sx={{
            display: "flex",
            maxWidth: "80%",
            mx: "auto"
          }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "center",
              mt: 2,
              minHeight: "100%"
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                textAlign: "left",
                pl: isDesktop ? 12 : 0,
                pr: isDesktop ? 12 : 0,
                width: "100%",
                mt: isDesktop ? 12 : 4
              }}>
                <Typography sx={{ fontSize: "18px !important" }}>
                  <FadeInUp><span style={{ fontSize: "22px" }}><strong> Welcome to our home in a peaceful neighborhood in Arusha, Tanzania! At HealthMission Africa, we've created a comfortable, secure space for our interns and volunteers to live while they're with us.</strong></span><br /><br /></FadeInUp>
                  {!isDesktop &&
                    <FadeInUp>
                      <img src={Arusha} alt="Arusha" style={{ width: "100%", marginBottom: "16px" }} />
                    </FadeInUp>
                  }
                  <FadeInUp>Our house is surrounded by a beautiful garden, where you can relax and enjoy the nature around you. It's a great spot for a bit of quiet time or for chatting with your housemates.<br /><br /></FadeInUp>
                  <FadeInUp>We take your safety seriously. Our home is in a gated area, and we have a guard on duty during the night. You can rest easy knowing that we've got security covered.<br /><br /></FadeInUp>
                  {!isDesktop &&
                    <FadeInUp>
                      <img src={Carousel3} alt="Home Picture 1" style={{ width: "100%", marginBottom: "16px" }} />
                    </FadeInUp>
                  }
                  <FadeInUp> Inside, you’ll find five rooms that can accommodate up to twelve people. This gives you the opportunity to meet and get to know healthcare professionals, students and volunteers from all over the world. Sharing the house is a fantastic way to learn from each other and build a supportive community.</FadeInUp>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "100%"
            }}>
              {isDesktop &&
                <FadeInUp style={{ width: "80%", marginTop: "56px" }}>
                  <img src={Arusha} alt="Arusha" style={{ width: "100%" }} />
                </FadeInUp>
              }
              {!isDesktop &&
                <FadeInUp style={{ width: "80%", marginTop: "16px" }}>
                  <img src={Carousel7} alt="Home Picture 2" style={{ width: "100%", marginBottom: "16px" }} />
                </FadeInUp>
              }
              <Typography sx={{ fontSize: "18px !important", width: "80%" }}>
                <FadeInUp>Our home is perfectly located to give you the best of both worlds. It's a quiet, peaceful spot, but it's also close to the city center of Arusha.<br /><br /></FadeInUp>
                <FadeInUp>We hope you'll enjoy staying in our home during your time with HealthMission Africa. We're excited to welcome you and help you have a truly memorable experience in Tanzania!</FadeInUp>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <FadeInUp>
          <Carousel
            autoPlay
            interval={isDesktop ? 4000 : 2000}
            indicators={false}
            navButtonsAlwaysVisible
            navButtonsProps={{
              style: {
                backgroundColor: "transparent",
                opacity: 1,
                marginRight: isDesktop ? "40px" : "22px",
                marginLeft: isDesktop ? "40px" : "22px",
              }
            }}
            NextIcon={<img src={isDesktop ? ArrowRight : ArrowRightMobile} alt="Next" style={{ background: "none", width: isDesktop ? "auto" : "32px", height: isDesktop ? "auto" : "32px" }} />}
            PrevIcon={<img src={isDesktop ? ArrowRight : ArrowRightMobile} alt="Previous" style={{ transform: "rotate(180deg)", background: "none", width: isDesktop ? "auto" : "32px", height: isDesktop ? "auto" : "32px" }} />}
            swipe={true}
          >
            {isDesktop ? carouselDesktopItems() : carouselMobileItems()}
          </Carousel>
        </FadeInUp>
      </Box>
      <ContactForm />
    </>
  );
};

export default OurHouse;
