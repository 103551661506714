import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FadeInUp from "bundles/common/utils/FadeInUp";
import { Box, Typography, Card, CardContent, CardMedia, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import MedicalAndHealthcare from "images/Medical-and-healthcare-small.png";
import Dentistry from "images/Dentistry-small.png";
import Physiotherapy from "images/Physiotherapy-card-2.png";
import ArrowRight from "images/arrow-right.svg";
import Title from "bundles/Main/components/Title";
import ContactForm from "bundles/Main/components/ContactForm";

const CareerFieldCard = ({ description, imageUrl, title }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles((theme) => ({
    card: {
      position: "relative",
      width: isDesktop ? "400px" : "86%",
      height: isDesktop ? "440px" : "460px",
      margin: isDesktop ? theme.spacing(2) : "1.2rem auto",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
        boxShadow: "0px 4px 20px rgba(0,0,0,0.25)",
        transition: "all 0.5s ease"
      }
    },
    media: {
      height: 280,
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
    title: {
      position: "absolute",
      bottom: 10,
      left: 10,
      color: "white",
      fontSize: "36px !important",
      padding: "5px",
      borderRadius: "5px",
      textShadow: "4px 4px 16px rgba(0, 0, 0, 0.6)"
    },
    arrowIcon: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.secondary
    },
  }));
  const classes = useStyles();

  return (
    <FadeInUp>
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={imageUrl}>
          <Typography variant ="h3" className={classes.title}>
            <em>{title}</em>
          </Typography>
        </CardMedia>
        <CardContent style={{ padding: "20px" }}>
          <Typography variant="body2" color="primary" style={{ fontSize: "14px" }}>
            {description}
          </Typography>
        </CardContent>
        {isDesktop &&
          <img src={ArrowRight} style={{ position: "absolute", right: "10px", bottom: "10px" }} />
        }
      </Card>
    </FadeInUp>
  );
};

const CareerFields = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector(state => state.i18nLocale);

  const title = "<span style='font-weight: 400;'>Career</span> <span style='font-weight: 800;'><em>fields<em/></span>";

  const studentsFields = [
    {
      description: (
        <>
          <strong>Are you a medical or nursing student/professional looking for a transformative experience in Tanzania?</strong><br />
          Join HealthMission Africa's medical internship program and gain hands-on experience along with valuable clinical knowledge at some of the largest public hospitals in Arusha, Tanzania.
        </>
      ),
      imageUrl: MedicalAndHealthcare,
      title: "Medical and Nursing",
      code: "medical_and_nursing"
    },
    {
      description: (
        <>
          <strong>Are you a dentistry student or professional seeking a unique learning experience in Tanzania?</strong><br />
          Look no further than HealthMission Africa's dentistry internship program, where you can gain hands-on clinical experience in a vibrant healthcare setting.
        </>
      ),
      imageUrl: Dentistry,
      title: "Dentistry",
      code: "dentistry"
    },
    {
      description: (
        <>
          <strong>Are you a physiotherapy student or professional seeking a unique learning experience in Tanzania? </strong><br />
          HealthMission Africa's physiotherapy internship program provides a perfect opportunity for you to gain clinical experience in a dynamic healthcare setting.
        </>
      ),
      imageUrl: Physiotherapy,
      title: "Physiotherapy",
      code: "physiotherapy"
    },
  ];

  const professionalsFields = [
    {
      description: (
        <>
          <strong>Are you passionated about child development and community improvement? </strong><br />
          Join HealthMission Africa's programs in Tanzania and gain experience along with valuable knowledge at schools, orphanages, and community organizations in Arusha, Tanzania.
        </>
      ),
      imageUrl: "/gallery-pictures/135_1280.webp",
      title: "Teaching, Social Work & Community Development",
      code: "volunteering"
    }
  ];

  return (
    <>
      <Box display="flex" flexDirection="column" bgcolor="#F8F8F8">
        <Title text={title} />

        <Box
          display="flex"
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent="space-between"
          style={{ marginTop: "1rem", marginBottom: "2rem", marginLeft: isDesktop ? "6rem" : "0.4rem", marginRight: isDesktop ? "6rem" : "0.4rem" }}
        >
          {studentsFields.slice(0, 3).map((field) => (
            <Link to={`/${i18nLocale}/career_fields/${field.code}`} key={field.imageUrl} style={{ textDecoration: "none" }}>
              <CareerFieldCard
                description={field.description}
                imageUrl={field.imageUrl}
                title={field.title}
              />
            </Link>
          ))}
        </Box>

        <Box width="100%" bgcolor="white">
          <Box display="flex" flexDirection={isDesktop ? "row" : "column"} justifyContent="flex-start" style={{ marginTop: "1rem", marginBottom: "2rem", marginLeft: isDesktop? "6rem" : "0.4rem" }}>
            {professionalsFields.map((field) => (
              <Link to={`/${i18nLocale}/career_fields/${field.code}`} key={field.imageUrl} style={{ textDecoration: "none" }}>
                <CareerFieldCard
                  description={field.description}
                  imageUrl={field.imageUrl}
                  title={field.title}
                />
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
      <ContactForm/>
    </>
  );
};

export default CareerFields;
