import React from 'react';
import ReactOnRails from 'react-on-rails';
import MainApp from '/client/bundles/Main/startup/MainApp';
import appStore from "bundles/common/store/appStore";

ReactOnRails.register({
  MainApp
});

ReactOnRails.registerStore({
  appStore,
});
